import React, { useState, useEffect } from 'react';
import './Home.css';
import background from '../assets/tww.png';
import logo from '../assets/logo.png';
import storyImage1 from '../assets/story3.png';
import storyImage2 from '../assets/story1.png';
import storyImage3 from '../assets/story4.png';
import storyImage4 from '../assets/story5.png';
import storyImage5 from '../assets/story2.png';
import storyImage6 from '../assets/story6.png';
import storyImage7 from '../assets/story7.png';
import storyImage8 from '../assets/story8.png';
import storyImage9 from '../assets/story9.png';
import storyImage10 from '../assets/story10.png';
import storyImage11 from '../assets/story11.png';
// import storyImage12 from '../assets/story12.png';
import storyImage13 from '../assets/story13.png';
import storyImage14 from '../assets/story14.png';
// import storyImage15 from '../assets/story15.png';
// import storyImage16 from '../assets/story16.png';
import storyImage17 from '../assets/story17.png';
// import storyImage18 from '../assets/story18.png';
import storyImage19 from '../assets/story19.png';
import leftArrow from '../assets/left-arrow.png';
import rightArrow from '../assets/right-arrow.png';
import connectWalletGif from '../assets/connect-wallet.gif';
import selectGameImage from '../assets/select-game.png';
import playWithNFTs from '../assets/play-with-nfts.gif';
import githubIcon from '../assets/github-icon.png';
import youtubeIcon from '../assets/youtube-icon.png';
import discordIcon from '../assets/discord-icon.png';
import twitterIcon from '../assets/twitter-icon.png';
import dojoIcon from '../assets/dojo-icon.png';
import starknetIcon from '../assets/starknet-icon.png';

const Home = () => {
  const images = [
    storyImage1, storyImage3, storyImage17, storyImage19, storyImage4, storyImage2, storyImage5, 
    storyImage6, storyImage7, storyImage8, storyImage9, storyImage10, 
    storyImage11, storyImage13, storyImage14, 
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    const firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

    window.onYouTubeIframeAPIReady = () => {
      window.player = new window.YT.Player('youtubeVideo', {
        videoId: '5VfKDK9YpgU',
        events: {
          onReady: onPlayerReady,
        },
      });

      window.trailerPlayer = new window.YT.Player('youtubeTrailer', {
        videoId: 'u19pHa7d9Uk',
        events: {
          onReady: onPlayerReady,
        },
      });
    };
  }, []);

  const onPlayerReady = (event) => {
    console.log('YouTube player is ready');
  };

  return (
    <div className="home">
      <div className="background" />
      <div className="content">
      <div className="icon-container left">
          <a href="https://starknet.io" target="_blank" rel="noopener noreferrer">
            <img src={starknetIcon} alt="StarkNet" className="social-icon" />
          </a>
          <a href="https://dojoengine.org" target="_blank" rel="noopener noreferrer">
            <img src={dojoIcon} alt="Dojo" className="social-icon" />
          </a>
        </div>
      <div className="icon-container right">
          <a href="https://github.com/taleweaver-ai" target="_blank" rel="noopener noreferrer">
            <img src={githubIcon} alt="GitHub" className="social-icon" />
          </a>
          <a href="https://www.youtube.com/@TaleWeaver-games" target="_blank" rel="noopener noreferrer">
            <img src={youtubeIcon} alt="YouTube" className="social-icon" />
          </a>
          <a href="https://discord.gg/Np445pprEn" target="_blank" rel="noopener noreferrer">
            <img src={discordIcon} alt="Discord" className="social-icon" />
          </a>
          <a href="https://x.com/taleweaverco?s=11" target="_blank" rel="noopener noreferrer">
            <img src={twitterIcon} alt="Twitter" className="social-icon" />
          </a>
        </div>
        <img src={logo} alt="Logo" className="logo-image" />
        <h1 className="logo">
          <span className="tale">TALE</span> <span className="weaver">WEAVER</span>
        </h1>
      </div>
      <div className="scroll-content">
        <div className="section">
        <h2 className="section-title">Play unique stories and create NFTs</h2>
          <div className="carousel">
            <img src={leftArrow} alt="Previous" className="arrow left" onClick={handlePrevClick} />
            <div className="carousel-container">
              {images.map((image, index) => (
                <img
                  key={index}
                  src={image}
                  alt={`Story ${index + 1}`}
                  className={`story-image ${index === currentIndex ? 'active' : ''}`}
                />
              ))}
            </div>
            <img src={rightArrow} alt="Next" className="arrow right" onClick={handleNextClick} />
          </div>
        </div>
        {/* Nueva sección: Create your own game */}
        <div className="section" id="videoSection">
          <h2 className="section-title">Create your own game</h2>
          <div className="video-container">
            <div id="youtubeVideo" width="100%" height="auto"></div>
          </div>
        </div>
        {/* Nueva sección: Use your favorites NFTs as avatars */}
        <div className="section" id="nftSection">
          <h2 className="section-title">Use your favorites NFTs as avatars</h2>
          <div className="nft-steps-container">
            <div className="nft-step">
              <img src={connectWalletGif} alt="Connect your wallet" className="nft-image" />
              <p>1. Connect with Braavos or Argent</p>
            </div>
            <div className="nft-step">
              <img src={selectGameImage} alt="Select the game" className="nft-image" />
              <p>2. Select game and avatar</p>
            </div>
            <div className="nft-step">
              <img src={playWithNFTs} alt="Play with your NFT as avatar" className="nft-image" />
              <p>3. Play with your NFT as avatar</p>
            </div>
          </div>
        </div>
        {/* Nueva sección: Trailer */}
        <div className="section" id="trailerSection">
          <h2 className="section-title">Check out our epic trailer!</h2>
          <div className="video-container">
            <div id="youtubeTrailer" width="100%" height="auto"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
